<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Calendar: Portal Term Setup</v-toolbar-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="info" v-on="on" style="margin-left:.5em" small>fas fa-question-circle</v-icon>
        </template>
        <span>Use this page to set up dates and settings within the Portal for the selected term.</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-menu v-model="termSelectMenu" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" @click="termFilter = ''">
            {{ selectedTerm.label }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text style="padding-top:0;padding-bottom:0">
            <v-text-field v-model="termFilter" ref="termFilterRef" append-icon="fal fa-search" label="Term Filter" @keyup.enter="filteredTermsList.length > 0 ? selectTerm(filteredTermsList[0]) : ''"></v-text-field>
          </v-card-text>
          <v-list style="max-height:400px;overflow:auto" class="mini-scrollbar">
            <v-list-item v-for="item in filteredTermsList" :key="item.code" @click="selectTerm(item)">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>
    <v-toolbar elevation="1" style="margin-bottom:10px;height:48px">
      <v-text-field :label="selectedTerm.term" :value="selectedTerm.label" readonly hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-text-field label="Start Date" :value="stringFormatDate(selectedTerm.start).substr(0, 17)" readonly hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-text-field label="End Date" :value="stringFormatDate(selectedTerm.end).substr(0, 17)" readonly hide-details></v-text-field>
    </v-toolbar>
    <v-tabs v-model="selectedTab">
      <v-tab v-if="isRecords">Campus Closures</v-tab>
      <v-tab v-if="isRecords">Schedule Change Messages</v-tab>
      <v-tab v-if="isRecords">Confirmation Checklist</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-if="isRecords">
        <closures :term-id="selectedTerm._id" :term-start="selectedTerm.start" :term-end="selectedTerm.end"></closures>
      </v-tab-item>
      <v-tab-item v-if="isRecords">
        <schedule-change :term-id="selectedTerm._id" :term-start="selectedTerm.start" :term-end="selectedTerm.end"></schedule-change>
      </v-tab-item>
      <v-tab-item v-if="isRecords">
        <checklist :term-start="selectedTerm.start" :term-end="selectedTerm.end" :term-id="selectedTerm._id" :confirmation="selectedTerm.confirmation"></checklist>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<style>
.v-input.v-input--is-readonly > .v-input__control > .v-input__slot:before {
  border-width: 0
}
</style>
<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate, yearMonDayFormat } from '../../helpers/formatters'

export default {
  components: {
    Closures: () => import('@/components/calendar/term-setup/closures'),
    ScheduleChange: () => import('@/components/calendar/term-setup/schedule-change'),
    Checklist: () => import('@/components/calendar/term-setup/checklist')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isRecords = computed(() => user.value.roles.includes('Technology Services') || user.value.roles.includes('Records'))
    const isStuDev = computed(() => user.value.roles.includes('Technology Services') || user.value.roles.includes('Student Development'))
    const selectedTab = ref(0)
    const selectedTerm = reactive({
      _id: '',
      term: '',
      label: '',
      start: '',
      end: '',
      closures: [],
      messages: [],
      housing: {
        start: '',
        end: '',
        phases: []
      }
    })
    const termsList = ref([])
    const termSelectMenu = ref(false)
    const termFilter = ref('')
    const termFilterRef = ref(null)
    const filteredTermsList = computed(() => {
      if (termFilter.value === '') return termsList.value
      let arr = []
      let regEx = new RegExp(termFilter.value, 'ig')
      termsList.value.forEach((row) => {
        if (regEx.test(row.label) || regEx.test(row.term)) arr.push(row)
      })
      return arr
    })
    function selectTerm (item) {
      for (let l in selectedTerm) {
        if (l in item) selectedTerm[l] = item[l]
        else selectedTerm[l] = ''
      }
      termSelectMenu.value = false
    }

    onMounted(() => {
      const dt = new Date()
      dt.setFullYear(dt.getFullYear() - 1)
      const now = new Date()
      root.$feathers.service('system/term').find({ query: { end: { $gte: dt }, $sort: { start: 1, end: 1, label: 1 }, $limit: 20 } }).then(({ data }) => {
        termsList.value = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === 'Traditional') {
            if (new Date(data[i].end) > now) {
              selectTerm(data[i])
              break
            }
          }
        }
      })
    })

    return {
      user,
      isRecords,
      isStuDev,
      selectedTab,
      selectedTerm,
      termsList,
      termSelectMenu,
      termFilter,
      termFilterRef,
      filteredTermsList,
      selectTerm,
      stringFormatDate,
      yearMonDayFormat
    }
  }
}
</script>
